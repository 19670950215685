<template>
  <MainWindow/>
</template>

<script>
import MainWindow from './components/MainWindow.vue'

export default {
  name: 'App',
  components: {
    MainWindow
  }
}
</script>

<style>
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

#app {
  font-family: Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* background-color: rgb(125, 172, 213); */
  background-image: url(/public/img/background.jpg);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  margin: 0;
  min-height: 100vh;
}
</style>
