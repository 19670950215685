<template>
    <div class="container">
        <div v-if="remainingTokens == undefined" id="modal-start" class="px-2">
            <img id="modal-start-child" src="/img/utake_logo.svg" style="max-width: 150px;">
        </div>
        <div id="modal-loading" class="px-2">
            <img id="loading-child" src="/img/utake_logo.svg" style="max-width: 150px;">
            <p class="fs-4 fw-bold text-white mt-3">{{ loadingText }}</p>
        </div>
        <div class="custom-height d-flex justify-content-center align-items-center">
            <div class="container d-flex justify-content-between align-items-center p-3 fixed-top">
                <span class="fs-1 fw-bold text-white">UTake</span>
                <span type="button" @click="connectWallet" id="wallet-wrapper"
                    class="btn btn-secondary fw-bold shadow text-white py-2 px-3">
                    <span id="wallet">Connect Wallet</span>
                </span>
            </div>
            <div class="row w-100 justify-content-center">
                <div class="custom-payment-box p-0">
                    <div class="position-relative bg-white shadow rounded px-2 pb-3 pt-2">
                        <div class="custom-message">{{ message }}</div>
                        <div class="bg-light rounded-1 overflow-hidden py-2">
                            <div class="input-group">
                                <span class="custom-background input-group-text ps-3" id="inputGroup-sizing-default"><img
                                        src="/img/utake_logo.svg" style="width: 35px;"></span>
                                <input type="text" data-field="ute"
                                    class="custom-background form-control text-dark fs-4 ps-3"
                                    style="border-right: none !important; border-left: none !important;" v-model="amountUTE"
                                    @keyup.enter="buyUTE" @keyup="checkInput" :placeholder="placeholder"
                                    aria-label="Sizing example input" aria-describedby="inputGroup-sizing-default">
                                <!-- <span class="custom-background input-group-text text-primary fw-bold" style="border-left: none !important; cursor: pointer;" @click="inputMaxUTE" id="inputGroup-sizing-default">MAX</span> -->
                            </div>
                            <span v-if="userUteBalance != undefined" class="custom-font-size text-primary fw-bold ps-3">Your
                                Balance: {{ userUteBalance }}</span>
                        </div>
                        <div class="d-flex justify-content-center py-1">
                            <span class="custom-font-size-2 text-primary fw-bold">1 UTE = {{ price }} USD</span>
                        </div>
                        <div class="bg-light rounded-1 overflow-x-hidden py-2">
                            <div class="input-group">
                                <span class="custom-background input-group-text ps-3" id="inputGroup-sizing-default">
                                    <div class="dropdown">
                                        <a class="dropdown-toggle" type="button" id="dropdownMenuButton1"
                                            data-bs-toggle="dropdown" aria-expanded="false">
                                            <img v-if="coinIndex == 0" src="/img/usdc.svg" style="height: 36px;">
                                            <img v-else-if="coinIndex == 1" src="/img/usdt.svg" style="height: 36px;">
                                            <img v-else src="/img/busd.svg" style="height: 36px;">
                                        </a>
                                        <ul class="dropdown-menu py-0 border-0 overflow-hidden"
                                            aria-labelledby="dropdownMenuButton1">
                                            <li class="custom-pointer border-bottom py-1 ps-3" data-coin="0"
                                                @click="selectCoin"><img src="/img/usdc.svg" class="pe-none"
                                                    style="height: 36px;"> USDC</li>
                                            <li class="custom-pointer border-bottom py-1 ps-3" data-coin="1"
                                                @click="selectCoin"><img src="/img/usdt.svg" class="pe-none"
                                                    style="height: 36px;"> USDT</li>
                                            <li class="custom-pointer py-1 ps-3" data-coin="2" @click="selectCoin"><img
                                                    src="/img/busd.svg" class="pe-none" style="height: 36px;">
                                                BUSD</li>
                                        </ul>
                                    </div>
                                </span>
                                <input type="text" data-field="usd" id="usd-input"
                                    class="custom-background form-control text-dark fs-4 ps-1"
                                    style="border-right: none !important; border-left: none !important;" v-model="amountUSD"
                                    @keyup.enter="buyUTE" @keyup="checkInput" :placeholder="placeholder"
                                    aria-label="Sizing example input" aria-describedby="inputGroup-sizing-default">
                                <span class="custom-background input-group-text text-primary fw-bold"
                                    style="border-left: none !important; cursor: pointer;" @click="inputMaxUSD"
                                    id="inputGroup-sizing-default">MAX</span>
                            </div>
                            <span v-if="userUsdBalance != undefined" id="usd-bal"
                                class="custom-font-size text-primary fw-bold ps-3">Your Balance: {{ userUsdBalance }}</span>
                        </div>
                        <div class="d-flex justify-content-center mt-3">
                            <button type="button" id="purchase-btn" @click="buyUTE"
                                class="btn btn-secondary shadow text-white fw-bold">PURCHASE</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { ref } from '@vue/reactivity'
import { providers, utils, Contract } from 'ethers'
import contract_sales_abi from '@/abi/UtakeSales.json'
import contract_token_abi from '@/abi/token.json'
const provider = new providers.JsonRpcProvider(process.env.VUE_APP_NODE_URL)
const contractPS = new Contract(process.env.VUE_APP_PRIVATE_SALES, contract_sales_abi, provider)
const contractUSDC = new Contract(process.env.VUE_APP_USDC_TOKEN, contract_token_abi, provider)
const contractUSDT = new Contract(process.env.VUE_APP_USDT_TOKEN, contract_token_abi, provider)
const contractBUSD = new Contract(process.env.VUE_APP_BUSD_TOKEN, contract_token_abi, provider)
const contractUTE = new Contract(process.env.VUE_APP_UTE_TOKEN, contract_token_abi, provider)

export default {
    name: "MainWindow",
    setup() {

        const placeholder = "0.0"
        const amountUTE = ref(undefined)
        const amountUSD = ref(undefined)
        const remainingTokens = ref(undefined)
        const userUteBalance = ref(0)
        const userUsdBalance = ref(0)
        const isConnected = ref(false)
        const coinIndex = ref(0)
        const message = ref("")
        const loadingText = ref('')
        const price = ref(undefined)

        setTimeout(async () => {
            let { status, acc } = await checkConnection()
            isConnected.value = status
            if (isConnected.value) {
                userUsdBalance.value = utils.formatEther(await contractUSDC.balanceOf(acc))
                userUteBalance.value = utils.formatEther(await contractUTE.balanceOf(acc))
            }
            const [pr, rt] = await contractPS.getBalanceAndPrice()
            price.value = parseInt(pr) / 100
            remainingTokens.value = utils.formatEther(rt)


            console.log(remainingTokens.value)
        })

        async function buyUTE() {
            if (amountUTE.value > 0) {
                loaddingModalAddRemove('add')
                loadingText.value = 'Please, approve on Metamask'
                try {
                    const ute = utils.parseEther(amountUTE.value.toString())
                    const usd = utils.parseEther(amountUSD.value.toString())
                    let resp = await buyUtakeToken(ute, usd, coinIndex.value, loadingText)
                    if (resp) {
                        loaddingModalAddRemove()
                        showMessage("You've purchased " + amountUTE.value + " UTE.", false)
                        amountUTE.value = ''
                        amountUSD.value = ''
                    }
                } catch (error) {
                    console.log(error)
                    showMessage("Error durring purchase! Try again!", true)
                    return
                }
                const [pr, rt] = await contractPS.getBalanceAndPrice()
                price.value = parseInt(pr) / 100
                remainingTokens.value = utils.formatEther(rt)
                let { acc } = await checkConnection()
                if (coinIndex.value == 0) {
                    userUsdBalance.value = utils.formatEther(await contractUSDC.balanceOf(acc))
                    userUteBalance.value = utils.formatEther(await contractUTE.balanceOf(acc))
                } else if (coinIndex.value == 1) {
                    userUsdBalance.value = utils.formatEther(await contractUSDT.balanceOf(acc))
                    userUteBalance.value = utils.formatEther(await contractUTE.balanceOf(acc))
                } else if (coinIndex.value == 2) {
                    userUsdBalance.value = utils.formatEther(await contractBUSD.balanceOf(acc))
                    userUteBalance.value = utils.formatEther(await contractUTE.balanceOf(acc))
                } else {
                    // Exeption
                }
            }
        }
        async function connectWallet() {
            try {
                loaddingModalAddRemove('add')
                await connectMetaMask(coinIndex.value)
                let { status, acc } = await checkConnection()
                isConnected.value = status
                if (isConnected.value) {
                    userUsdBalance.value = utils.formatEther(await contractUSDC.balanceOf(acc))
                    userUteBalance.value = utils.formatEther(await contractUTE.balanceOf(acc))
                    if (amountUSD.value > userUsdBalance.value && isConnected.value) {
                        warnUserBalanceOverflow("warn")
                    } else {
                        warnUserBalanceOverflow("")
                    }
                }
                loaddingModalAddRemove()
            } catch (error) {
                showMessage("Error durring connection! Try again!", true)
                loaddingModalAddRemove()

            }

        }

        function showMessage(text, isWarn) {
            try {
                loaddingModalAddRemove()
            } catch (error) {
                console.log(error)
            }
            message.value = text
            const notification = document.querySelector('.custom-message')
            if (isWarn) {
                notification.classList.add('warn')
            }
            notification.classList.add('active')
            setTimeout(() => {
                notification.classList.remove('active')
            }, 5000)
            setTimeout(() => {
                notification.classList.remove('warn')
            }, 5500)
        }

        // function inputMaxUTE() {
        //     amountUTE.value = remainingTokens.value
        //     calcAmountUSD()
        // }

        function inputMaxUSD() {
            amountUSD.value = userUsdBalance.value
            calcAmountUTE()
            calcAmountUSD()
        }

        function calcAmountUSD() {
            if (amountUTE.value === "") {
                amountUSD.value = ""
            }
            else {
                if (parseFloat(amountUTE.value) > remainingTokens.value) {
                    console.log(remainingTokens.value)
                    amountUTE.value = parseFloat(remainingTokens.value)
                } else if (amountUTE.value < 1 || amountUTE.value == ".") {
                    amountUTE.value = 1
                }
                amountUSD.value = (amountUTE.value * price.value)
                if (parseFloat(amountUSD.value) > userUsdBalance.value && isConnected.value) {
                    warnUserBalanceOverflow("warn")
                } else {
                    warnUserBalanceOverflow("")
                }
            }
        }

        function calcAmountUTE() {
            if (amountUSD.value === "") {
                amountUTE.value = ""
            } else if (amountUSD.value == ".") {
                amountUSD.value = ""
                amountUTE.value = ""
            }
            else {
                if (parseFloat(amountUSD.value) > userUsdBalance.value && isConnected.value) {
                    warnUserBalanceOverflow("warn")
                } else {
                    warnUserBalanceOverflow("")
                }
                amountUTE.value = (amountUSD.value / price.value)
                if (parseFloat(amountUTE.value) > remainingTokens.value) {
                    amountUTE.value = remainingTokens.value
                } else if (amountUTE.value < 1) {
                    amountUTE.value = 1
                }
            }
        }

        function openModal() {
            const modal = document.querySelector(".custom-modal")
            const child = document.querySelector(".custom-modal-child")
            modal.classList.add("inter")
            setTimeout(() => {
                modal.classList.add("active")
                child.classList.add("active")
            })
        }

        async function selectCoin({ target }) {
            const symbol = target.dataset.coin
            coinIndex.value = symbol
            let { acc } = await checkConnection()
            if (symbol == 0) {
                userUsdBalance.value = parseFloat(utils.formatEther(await contractUSDC.balanceOf(acc)))
            } else if (symbol == 1) {
                userUsdBalance.value = parseFloat(utils.formatEther(await contractUSDT.balanceOf(acc)))
            } else {
                userUsdBalance.value = parseFloat(utils.formatEther(await contractBUSD.balanceOf(acc)))
            }
        }

        function checkInput(e) {
            let keynum;
            if (window.event) {
                keynum = e.keyCode;
            } else if (e.which) {
                keynum = e.which;
            }

            const input = e.target.getAttribute("data-field")
            if (keynum == 188) {
                if (input == "ute") {
                    amountUTE.value = amountUTE.value.replace(",", ".")
                } else {
                    amountUSD.value = amountUSD.value.replace(",", ".")
                }
            }

            if (input == "ute") {
                if (amountUTE.value != "") {
                    amountUTE.value = amountUTE.value.replace(/[^0-9.]/gi, '')
                    calcAmountUSD()
                } else {
                    amountUSD.value = ""
                }
            } else {
                if (amountUSD.value != "") {
                    amountUSD.value = amountUSD.value.replace(/[^0-9.]/gi, '')
                    calcAmountUTE()
                } else {
                    amountUTE.value = ""
                }
            }
        }

        return {
            placeholder, amountUTE, amountUSD, userUteBalance, userUsdBalance, coinIndex, message,
            remainingTokens, calcAmountUSD, calcAmountUTE, buyUTE, connectWallet, inputMaxUSD,
            openModal, selectCoin, loadingText, price, checkInput
        }
    }
}
function warnUserBalanceOverflow(toggle) {
    const bal = document.querySelector("#usd-bal")
    const input = document.querySelector("#usd-input")
    const btn = document.querySelector("#purchase-btn")
    if (toggle == "warn") {
        bal.classList.remove("text-primary")
        input.classList.remove("text-dark")
        bal.classList.add("text-danger")
        input.classList.add("text-danger")
        btn.setAttribute('disabled', '');

    } else {
        bal.classList.remove("text-danger")
        input.classList.remove("text-danger")
        bal.classList.add("text-primary")
        input.classList.add("text-dark")
        btn.removeAttribute('disabled');
    }
}

function loaddingModalAddRemove(addRemove) {
    const loadPage = document.querySelector('#modal-loading')
    if (addRemove == 'add') {
        if (!loadPage.classList.contains('inter')) {
            loadPage.classList.add('inter')
        }
        if (!loadPage.classList.contains('active')) {
            setTimeout(() => {
                loadPage.classList.add("active")
            })
        }
        return
    } else {
        if (loadPage.classList.contains('inter')) {
            loadPage.classList.remove('inter')
        }
        if (loadPage.classList.contains('active')) {
            setTimeout(() => {
                loadPage.classList.remove("active")
            })
        }
    }
}

async function buyUtakeToken(ute, usd, index, loadingText) {
    try {
        const { token, privateSales } = await connectMetaMask(index)
        console.log(usd)
        console.log(ute)
        console.log(index)
        const resp = await token.approve(process.env.VUE_APP_PRIVATE_SALES, usd)
        loadingText.value = 'Pending approval by the chain'
        const load = await resp.wait()
        if (load) {
            loadingText.value = 'Please, approve purchase on Metamask'
            const resp = await privateSales.buyExactUtakeForTokens(ute, index)
            loadingText.value = 'Pending approval by the chain'
            const load = await resp.wait()
            return load
        }
    } catch (error) {
        console.log(error)
        throw new Error('Purchase error')

    }
}

const networks = {
    56: {
        chainId: `0x${Number(56).toString(16)}`,
        chainName: "Smart Chain",
        nativeCurrency: {
            name: "BINANCE",
            symbol: "BNB",
            decimals: 18
        },
        rpcUrls: ["https://bsc-dataseed.binance.org/"],
        blockExplorerUrls: ['https://bscscan.com']
    },
    97: {
        chainId: `0x${Number(97).toString(16)}`,
        chainName: "BSC Testnet",
        nativeCurrency: {
            name: "BINANCE",
            symbol: "BNB",
            decimals: 18
        },
        rpcUrls: ["https://data-seed-prebsc-1-s1.binance.org:8545"],
        blockExplorerUrls: ["https://explorer.binance.org/smart-testnet"]
    }
}

async function checkNetwork(chainId) {
    try {
        await window.ethereum.request({
            method: 'wallet_switchEthereumChain',
            params: [{ chainId: `0x${Number(chainId).toString(16)}` }]
        })

    } catch (switchError) {
        // This error code indicates that the chain has not been added to MetaMask.
        try {
            await window.ethereum.request({
                method: 'wallet_addEthereumChain',
                params: [
                    networks[chainId]
                ],
            });
            return true
        } catch (addError) {
            // handle "add" error
            console.log(addError)
        }
        // handle other "switch" errors
    }
}

async function connectMetaMask(index) {
    let p = new providers.Web3Provider(await window.ethereum)
    const acc = await p.send("eth_requestAccounts", []);
    if (acc) {
        const { chainId } = await p.getNetwork()
        if (chainId != process.env.VUE_APP_NETWORK) {
            await checkNetwork(process.env.VUE_APP_NETWORK)
            p = new providers.Web3Provider(await window.ethereum)
            await p.send("eth_requestAccounts", [])
        }
    }
    const signer = p.getSigner()
    changeWalletButton(await signer.getAddress())
    let token
    if (index == 0) {
        token = new Contract(process.env.VUE_APP_USDC_TOKEN, contract_token_abi, signer)
    } else if (index == 1) {
        token = new Contract(process.env.VUE_APP_USDT_TOKEN, contract_token_abi, signer)
    } else {
        token = new Contract(process.env.VUE_APP_BUSD_TOKEN, contract_token_abi, signer)
    }
    const privateSales = new Contract(process.env.VUE_APP_PRIVATE_SALES, contract_sales_abi, signer)
    return { token, privateSales, signer }
}

async function checkConnection() {
    try {
        if (window.ethereum == undefined) {
            console.log('Install metamask')
            return { connected: false, signer: null }
        } else {
            return await window.ethereum.request({ method: 'eth_accounts' }).then(handleAccountsChanged).catch(console.error);
        }
    } catch (error) {
        console.log(error)
        return { connected: false, signer: null }
    }

}

function changeWalletButton(address) {
    const connectWalletBtn = document.getElementById('wallet')
    const connectWalletBtnWrap = document.getElementById('wallet-wrapper')
    connectWalletBtn.innerHTML = address.substring(0, 6) + '...' + address.substring(38, 42)
    connectWalletBtn.classList.add("text-secondary")
    connectWalletBtnWrap.classList.remove("btn-secondary")
    connectWalletBtnWrap.classList.add("btn-outline-secondary")
}

async function handleAccountsChanged(accounts) {
    const connectWalletBtn = document.getElementById('wallet');
    const connectWalletBtnWrap = document.getElementById('wallet-wrapper')

    if (accounts.length === 0) {
        connectWalletBtn.innerHTML = "Connect Wallet"
        return { status: false, acc: null }
    } else {
        window.ethereum.on('accountsChanged', () => {
            window.location.reload()

        })
        const p = new providers.Web3Provider(await window.ethereum)
        const signer = p.getSigner()
        const address = await signer.getAddress()
        // connectWalletBtn.innerHTML = `<a class="wallet-id">${accounts[0]}</a>`
        connectWalletBtn.innerHTML = address.substring(0, 6) + '...' + address.substring(38, 42)
        connectWalletBtn.classList.add("text-secondary")
        connectWalletBtnWrap.classList.remove("btn-secondary")
        connectWalletBtnWrap.classList.add("btn-outline-secondary")
        return { status: true, acc: address }
    }
}

</script>

<style scoped>
#wallet-wrapper.btn-outline-secondary:hover #wallet {
    color: white !important;
}

.custom-message {
    position: absolute;
    top: -50px;
    left: 50%;
    width: 90%;
    transform: translate(-50%, -25px);
    background-color: rgb(198, 248, 181);
    border-radius: 10px;
    padding: 10px 7px;
    text-align: center;
    opacity: 0;
    transition: all .5s ease-in-out;
}

.custom-message.warn {
    background-color: rgb(248, 181, 181) !important;
    transition: all .5s ease-in-out;
}

.custom-message.active {
    opacity: 1;
    transform: translate(-50%, 0);
    transition: all .5s ease-in-out;
}

#modal-start {
    display: flex !important;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1035 !important;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.902);
}

#modal-start-child {
    animation: loading infinite 1.2s;
}

#modal-loading {
    display: none !important;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1035 !important;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.802);
    opacity: 0;
}

#modal-loading.inter {
    display: flex !important;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    opacity: 0;
    transition: all .2s ease-in-out;
}

#modal-loading.inter.active {
    opacity: 1;
}

#loading-child {
    animation: loading infinite 1.2s;
}

.custom-modal {
    display: none !important;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1031 !important;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.561);
    opacity: 0;
}

.custom-modal.inter {
    display: flex !important;
    justify-content: center;
    align-items: center;
    opacity: 0;
    transition: all .2s ease-in-out;
}

.custom-modal.inter.active {
    opacity: 1;
}

.custom-modal-child {
    transform: scale(.3);
    opacity: 0;
    transition: all .25s ease-in-out;
}

.custom-modal-child.active {
    transform: scale(1);
    opacity: 1;
    transition: all .25s ease-in-out;
}

.custom-modal-child span {
    display: block;
}

.custom-modal-child span a {
    text-decoration: none;
}

.custom-payment-box {
    max-width: 500px;
    width: 100%;
}

.custom-height {
    min-height: 100vh;
}

.custom-background {
    background-color: #E8EEF6 !important;
}

.custom-font-size {
    font-size: 12px;
    opacity: .6;
}

.custom-font-size-2 {
    font-size: 10px;
}

.custom-position {
    position: absolute;
    bottom: 8px;
    right: 8px;
    width: 25px;
    cursor: pointer;
}

#short-logo {
    display: none;
}

.dropdown-menu {
    top: 10px !important;
    left: -15px !important;
}

.custom-pointer {
    background-color: white;
    cursor: pointer;
    transition: all .25s ease-in-out;
}

.custom-pointer:hover {
    background-color: rgb(183, 205, 232);
}

@keyframes loading {
    0% {
        opacity: 0;
        transform: scale(.3);
    }

    50% {
        opacity: 1;
        transform: none;
    }
}


@media (max-width: 520px) {
    #short-logo {
        display: inline;
    }

    #long-logo {
        display: none;
    }
}
</style>